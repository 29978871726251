<template>
	<layouts-basic>
		<div class="pb-7">
			<h3 class="ls-tight fw-bolder h3">Cambiar contraseña</h3>
			<p class="text-sm text-muted">Para actualizar la contraseña, completá el siguiente formulario.</p>
		</div>
		<form @submit.prevent="submit">
			<div class="mb-3">
				<div class="form-floating" :class="{'is-invalid': validation.password.$error}">
					<input type="password" id="password" v-model="password" class="form-control" :class="{'is-invalid': validation.password.$error}" placeholder="Ingresá la contraseña" autocomplete="new-password" v-focus>
					<label for="password">Nueva contraseña</label>
				</div>
				<span class="mt-1 invalid-feedback" v-for="error of validation.password.$errors" :key="error.$uid">{{ error.$message }}</span>
			</div>
			<div class="mb-3">
				<div class="form-floating" :class="{'is-invalid': validation.password_confirm.$error}">
					<input type="password" id="password_confirm" v-model="password_confirm" class="form-control" :class="{'is-invalid': validation.password_confirm.$error}" placeholder="Ingresá nuevamente la contraseña" autocomplete="new-password">
					<label for="password_confirm">Repetir contraseña</label>
				</div>
				<span class="mt-1 invalid-feedback" v-for="error of validation.password_confirm.$errors" :key="error.$uid">{{ error.$message }}</span>
			</div>
			<div class="mb-3 text-center" v-if="validationExternal.length > 0">
				<div class="invalid-feedback d-block" v-for="(error, index) in validationExternal" v-bind:key="index">{{ error }}</div>
			</div>
			<div class="mt-5">
				<button type="submit" class="btn btn-primary w-100" :disabled="submitting">Cambiar contraseña</button>
			</div>
		</form>
		<div class="mt-5">
			<button type="button" class="btn btn-neutral w-100" @click="redirectBack">Cancelar</button>
		</div>
	</layouts-basic>
</template>

<script>
	import { reactive, toRefs } from 'vue';
	import useVuelidate from '@vuelidate/core';
	import { required, minLength, maxLength, sameAs } from '@/helpers/i18n/validators';
	import MeRepository from '@/repositories/MeRepository';
	import composableRedirect from '@/composables/redirect';
	import composableForm from '@/composables/form';

	export default {
		setup() {
			const { redirectBack } = composableRedirect();
			const { submitting, validationExternal, catchError } = composableForm();

			const form = reactive({
				password: '',
				password_confirm: ''
			});

			const { password } = toRefs(form, 'form');

			const validation = useVuelidate({
				password: {
					required,
					minLength: minLength(8),
					maxLength: maxLength(16)
				},
				password_confirm: {
					required,
					sameAs: sameAs(password, 'nueva contraseña')
				}
			}, form);

			async function submit() {
				validationExternal.value = [];
				if(!await validation.value.$validate()) return;

				submitting.value = true;
				MeRepository.update(form).then(redirectBack).catch(catchError);
			}

			return { redirectBack, ...toRefs(form), validation, validationExternal, submitting, submit };
		}
	}
</script>